$(document).ready(function(){
	$(".fancy_iframe").fancybox({
		maxWidth	: 1100,maxHeight	: 650,
		fitToView	: true,
		width		: "100%",height		: "100%",
		autoSize	: false,closeClick	: false,
		openEffect	: "none",closeEffect: "none"
	});
			 
	//fade: false, alwaysOn: true
	$("#container_map .map").maphilight({
		fade: false, alwaysOn: true,
		strokeColor: "f70201",strokeWidth: 2,
		fill: true,fillColor: "daacfc",fillOpacity: 0.6,
		fade: true
	});

	$(".picture").smoothZoom({
		width: "100%",height: "100%",
		max_HEIGHT: 900,
		pan_BUTTONS_SHOW: "NO",
		button_SIZE: 24,
		button_ALIGN: "top right",
		animation_SPEED_PAN: 4,
		animation_SPEED_ZOOM: 4,
		animation_SMOOTHNESS: 5,
		container: "picture_frame",
		responsive_maintain_ratio: true,
		responsive: true,
		initial_ZOOM: 40,
		initial_POSITION: "3150 2900"
	});
			
	$("#container_map .location").click(function(e){
	  if($(this).attr("id6") == 3){
		var id2 = $(this).attr("id2");
		var id3 = $(this).attr("id3");
		var id4 = $(this).attr("id4");
		var id5 = $(this).attr("id5");
		$(".location").removeClass("active");
		if(id2 == 1){
			$("#container_map .location").attr("id2", 1);
			$("#container_map .picture").smoothZoom("focusTo", {x:id4, y:id5, zoom:180, speed:2});
			$("#container_map #obmocje_" + id3).trigger("mouseenter");
			$("#container_map #location" + id3).addClass("active");
			$("#container_map #active_hover").val(2);
			$(this).attr("id2", 2);
		}else{
			$("#container_map .picture").smoothZoom("Reset");
			$("#container_map .location").attr("id2", 1);
			$("#container_map #hover_close_" + id3).trigger("click");
			$("#container_map #active_hover").val("");
		}
		$(this).attr("id6", "2");
		setTimeout(function(){$("#container_map .location").attr("id6", "3");}, 600);
	  }else{
		  e.preventDefault();
	  }
	});
			
	function Demo_Function (x, y, id, title, image) {

		var url = $("#location" + id).data('url');

	  $("#container_map .picture").smoothZoom("addLandmark", 
		[
		'<div class="item mark hover_mark" data-position="' + x + ', ' + y + '" data-show-at-zoom="0" data-allow-scale="false">\
		  <div class="text" style="width:220px;text-align:left;">\
			<div class="text_top_frame"><span class="hover_close" id="hover_close_' + id + '" id4="' + id + '"><span class="icon-times"></span></span></div>\
			<a href="'+ url +'"><table><tr valign="top"><td><div class="mark_title">' + title + '</div><div class="mark_subtitle">Poslovna cona</div><div class="mark_link">Podrobnost obmo&#269;ja</div></td></tr></table></a>\
		  </div>\
		</div>'
		]
	  );
	}
			
	var timer;
	$("#container_map .map_details").mouseenter(function(){
		var id2 = $(this).attr("id2");
		var id3 = $(this).attr("id3");
		var id4 = $(this).attr("id4");
		var id5 = $(this).attr("id5");
		var id6 = $(this).attr("id6");
		var active_id = $("#container_map #active_tooltip").val();
		
		timer = setTimeout(function(){
			
			
			$("#container_map .picture").smoothZoom("removeLandmark");
			Demo_Function (id2, id3, id4, id5, id6);
			
			//var data = $("#obmocje_" + id4).mouseout().data("maphilight") || {};
			//data.alwaysOn = !data.alwaysOn;
			//$("#obmocje_" + id4).data("maphilight", data).trigger("alwaysOn.maphilight");
			
			//if(active_id!=null){
				//var data = $("#obmocje_" + active_id).mouseout().data("maphilight") || {};
				//data.alwaysOn = !data.alwaysOn;
				//$("#obmocje_" + active_id).data("maphilight", data).trigger("alwaysOn.maphilight");
			//}
			
			$("#container_map #active_tooltip").val(id4);
			
			$("#container_map .hover_close").click(function(e){
				e.preventDefault();
				var id4 = $(this).attr("id4");
				$("#container_map .picture").smoothZoom("removeLandmark");
				//var data = $("#obmocje_" + id4).mouseout().data("maphilight") || {};
				//data.alwaysOn = !data.alwaysOn;
				//$("#obmocje_" + id4).data("maphilight", data).trigger("alwaysOn.maphilight");
				$("#container_map #active_tooltip").val("");
			});
			
			
		}, 400);
	}).mouseleave(function() {
		clearTimeout(timer);
		var active_id = $("#container_map #active_tooltip").val();
		var disable_hover = $("#container_map #active_hover").val();
		if(active_id!=null){
		  var hovered = 2;
		  var timer2;
		  $("#container_map .hover_mark").mouseenter(function(){
			  hovered = 1;
			  clearTimeout(timer2);
		  }).mouseleave(function() {
			  hovered = 1;clearTimeout(timer2);
			  $("#container_map .picture").smoothZoom("removeLandmark");
			  $("#container_map #active_tooltip").val("");
		  });
		  timer2 = setTimeout(function(){
		    if(hovered == 2){
				$("#container_map .picture").smoothZoom("removeLandmark");
				$("#container_map #active_tooltip").val("");
		    }
		  }, 300);
		}
	});
			
	$("#container_map .map_details").click(function(e){
		e.preventDefault();
		var id2 = $(this).attr("id2");
		var id3 = $(this).attr("id3");
		var id4 = $(this).attr("id4");
		var id5 = $(this).attr("id5");
		var id6 = $(this).attr("id6");
		var active_id = $("#container_map #active_tooltip").val();
		
		$("#container_map .picture").smoothZoom("removeLandmark");
		Demo_Function (id2, id3, id4, id5, id6);
		$("#container_map #active_tooltip").val(id4);
		
		$("#container_map .hover_close").click(function(e){
			e.preventDefault();var id4 = $(this).attr("id4");
			$("#container_map .picture").smoothZoom("removeLandmark");
			$("#container_map #active_tooltip").val("");
		});
	});

	$("#container_map .change_map").click(function(){
		var id2 = $(this).attr("id2");
		var new_image = "/img/poslovne-cone/" + $(this).attr("id3") + ".jpg";
		
		$("#container_map .change_map").removeClass("btn_active");
		$("#container_map .change_map" + id2).addClass("btn_active");
		
		$("#container_map .map").attr("src", new_image);
		$("#container_map .map").parent().css("background-image","url(" + new_image + ")");
	});
});